import { UIPanel, UIRow } from '/src/plugins/editor/js/libs/ui.js';
import { SetPositionCommand } from '/src/plugins/editor/js/commands/SetPositionCommand.js';


function CustomFunctions(editor) {
	var strings = editor.strings;

	var container = new UIPanel();
	container.setClass('menu');

	var title = new UIPanel();
	title.setClass('title');
	title.setTextContent(strings.getKey('menubar/custom-function'));
	container.add(title);

	var options = new UIPanel();
	options.setClass('options');
	container.add(options);

	// Examples

	var items = [
		{ title: 'menubar/custom-function/center-scene', action: 'center' },

	];

	for (var i = 0; i < items.length; i++) {
		(function (i) {
			var item = items[i];

			var option = new UIRow();
			option.setClass('option');
			option.setTextContent(strings.getKey(item.title));
			option.onClick(async function () {
				if (item.action == 'center') {

					//  editor.camera.rotation.set(-26.57,0, 0);
					editor.scene.children[0].position.set(0, 0, 0);
					editor.camera.lookAt(new THREE.Vector3());;
					var newPosition = new THREE.Vector3(0, 0, 0);
					var cameraPosition = new THREE.Vector3(0, 5, 10);
					editor.execute(new SetPositionCommand(editor, editor.scene.children[0], newPosition));
					editor.execute(new SetPositionCommand(editor, editor.camera, cameraPosition));
				}
				if (item.action == 'save') {

				}
			});
			options.add(option);
		})(i);
	}


	return container;
}

export { CustomFunctions };
