import "./editor/js/libs/codemirror/addon/dialog.css";
import "./editor/js/libs/codemirror/addon/show-hint.css";
import "./editor/js/libs/codemirror/addon/tern.css";
import "./editor/js/libs/codemirror/codemirror.css";
import "./editor/js/libs/codemirror/theme/monokai.css";
import "./editor/css/main.scss";
import store from '@/store'
import * as THREE from "./build/three.module.js";
import { CustomEditor } from "../custom-functions/custom-Editor.js";
import { CustomStrings } from "../custom-functions/custom-Strings.js";
import { CustomViewport } from "../custom-functions/custom-Viewport";
import { Toolbar } from "../custom-functions/custom-Toolbar.js";
// import { Script } from "./editor/js/Script.js";
import { Player } from './editor/js/Player.js';
import { CustomSidebar } from "/src/custom-functions/custom-Sidebar.js";;
import { Menubar } from "/src/custom-functions/custom-Menubar";
import { Resizer } from "./editor/js/Resizer.js";
import { VRButton } from "./examples/jsm/webxr/VRButton.js";

import { loadGLBAsync } from "../custom-functions/utils.js"
import { CustomAddObjectCommand } from '../custom-functions/custom-AddObjectCommand.js';

import router from "@/router";

export default class RevolvaEditor {
  viewport = null;
  editor = null;
  toolbar = null;
  script = null;
  player = null;
  sidebar = null;
  menubar = null;
  resizer = null;
  isLoadingFromHash = true;
  selected = null;
  timeout = null;
  signals = null;
  hash = null;
  loader = null;
  file = null;
  envMap = null;

  constructor(parentId) {
    this.init(parentId)
  }

  async init(parentId) {
    window.URL = window.URL || window.webkitURL;
    // @ts-ignore: Unreachable code error
    window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;
    // @ts-ignore: Unreachable code error
    Number.prototype.format = function () {
      return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    this.editor = new CustomEditor('revolva');
    this.editor.strings = CustomStrings(this.editor.config)
    // @ts-ignore: Unreachable code error
    window.editor = this.editor; // Expose editor to Console
    // @ts-ignore: Unreachable code error
    window.THREE = THREE; // Expose THREE to APP Scripts and Console
    // @ts-ignore: Unreachable code error
    window.VRButton = VRButton; // Expose VRButton to APP Scripts
    //console.log(this.editor);

    // @ts-ignore: Unreachable code error
    this.viewport = new CustomViewport(this.editor);
    //console.log(this.viewport.dom);
    //console.log(document.getElementById("revolva-editor"));
    document.getElementById(parentId).appendChild(this.viewport.dom);

    // @ts-ignore: Unreachable code error
    this.toolbar = new Toolbar(this.editor);
    document.getElementById(parentId).appendChild(this.toolbar.dom);

    // @ts-ignore: Unreachable code error
    // this.script = new Script(this.editor);
    // document.getElementById(parentId).appendChild(this.script.dom);

    this.player = new Player(this.editor);
    document.getElementById("revolva-editor").appendChild(this.player.dom);

    // @ts-ignore: Unreachable code error
    this.sidebar = new CustomSidebar(this.editor, parentId);
    document.getElementById(parentId).appendChild(this.sidebar.dom);

    // @ts-ignore: Unreachable code error
    this.menubar = new Menubar(this.editor);
    document.getElementById(parentId).appendChild(this.menubar.dom);

    // @ts-ignore: Unreachable code error
    this.resizer = new Resizer(this.editor);
    document.getElementById(parentId).appendChild(this.resizer.dom);

    this.editor.storage.init(() => {
      this.editor.storage.get((state) => {
        if (this.isLoadingFromHash) return;
        if (state !== undefined) {
          this.editor.fromJSON(state);
        }

        this.selected = this.editor.config.getKey("selected");

        if (this.selected !== undefined) {
          this.editor.selectByUuid(this.selected);
        }
      });


    });
    
    const project = store.getters['Project/getProject'];
    const editorSettings = store.state.Editor.cameraSetting != undefined ? store.state.Editor.cameraSetting : {};
    store.commit('Editor/SET_MODEL_LOADING', true)

    store.dispatch('Project/loadProject', store.state.Project.project.id)
      .then(() => {
        store.dispatch('Project/getDevelopmentProjectFiles').then(async () => {
          this.productData = store.getters['Project/getCurrentProjectFiles'];
          const development_project_files = store.getters['Project/getCurrentProjectFiles']
          const JSONParser = new THREE.ObjectLoader();

          await loadGLBAsync(development_project_files.glb_file_s3_path, project).then((scene) => {
            store.commit('Editor/SET_MODEL_LOADING', false)
            this.editor.execute(new CustomAddObjectCommand(this.editor, scene));
          })
          this.signals = this.editor.signals;
          this.signals.geometryChanged.add(this.saveState);
          this.signals.objectAdded.add(this.saveState);
          this.signals.objectChanged.add(this.saveState);
          this.signals.objectRemoved.add(this.saveState);
          this.signals.materialChanged.add(this.saveState);
          this.signals.sceneBackgroundChanged.add(this.saveState);
          this.signals.sceneFogChanged.add(this.saveState);
          this.signals.sceneGraphChanged.add(this.saveState);
          this.signals.scriptChanged.add(this.saveState);
          this.signals.historyChanged.add(this.saveState);

          // Add the lights from settings to the scene
          if (editorSettings.lights) {
            for (let light of editorSettings.lights) {
              let threeLight = JSONParser.parse(light.lightObject)
              let lightsParent = editor.scene.getObjectByProperty('name', light.parentName)
              lightsParent.add(threeLight)
            };
          }
        })
    })
          
    /*} else {
      this.importEditor(development_project_files.glb_file_s3_path);
      this.signals = this.editor.signals;
      this.signals.geometryChanged.add(this.saveState);
      this.signals.objectAdded.add(this.saveState);
      this.signals.objectChanged.add(this.saveState);
      this.signals.objectRemoved.add(this.saveState);
      this.signals.materialChanged.add(this.saveState);
      this.signals.sceneBackgroundChanged.add(this.saveState);
      this.signals.sceneFogChanged.add(this.saveState);
      this.signals.sceneGraphChanged.add(this.saveState);
      this.signals.scriptChanged.add(this.saveState);
      this.signals.historyChanged.add(this.saveState);
    }*/
    // });

    document.addEventListener(
      "dragover",
      (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
      },
      false
    );

    document.addEventListener(
      "drop",
      (event) => {
        event.preventDefault();

        if (event.dataTransfer.types[0] === "text/plain") return; // Outliner drop

        if (event.dataTransfer.items) {
          // DataTransferItemList supports folders

          this.editor.loader.loadItemList(event.dataTransfer.items);
        } else {
          this.editor.loader.loadFiles(event.dataTransfer.files);
        }
      },
      false
    );

    window.addEventListener("resize", this.onWindowResize, false);

    this.onWindowResize();

    this.hash = window.location.hash;

    if (this.hash.substr(1, 5) === "file=") {
      this.file = this.hash.substr(6);

      if (confirm("Any unsaved data will be lost. Are you sure?")) {
        this.loader = new THREE.FileLoader();
        this.loader.crossOrigin = "";
        this.loader.load(this.file, (text) => {
          this.editor.clear();
          this.editor.fromJSON(JSON.parse(text));
        });

        this.isLoadingFromHash = true;
      }
    }

    // ServiceWorker

    // if ("serviceWorker" in navigator) {
    //     console.log(window.location.pathname);
    //     try {
    //         navigator.serviceWorker.register(window.location.origin + "/out/editor/sw.js");
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
  }

  saveState = () => {
    if (this.editor.config.getKey("autosave") === false) return;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.editor.signals.savingStarted.dispatch();

      this.timeout = setTimeout(() => {
        this.editor.storage.set(this.editor.toJSON());

        this.editor.signals.savingFinished.dispatch();
      }, 100);
    }, 1000);
  }

  onWindowResize() {
    this.editor.signals.windowResize.dispatch();
  }
  makeid(length) {
    var result = [];
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join("");
  }
}
