import { UITabbedPanel, UISpan } from '/src/plugins/editor/js/libs/ui.js';

import { SidebarScene } from '/src/plugins/editor/js/Sidebar.Scene.js';
import { SidebarProperties } from '/src/plugins/editor/js/Sidebar.Properties.js';
import { SidebarScript } from '/src/plugins/editor/js/Sidebar.Script.js';
import { SidebarAnimation } from '/src/plugins/editor/js/Sidebar.Animation.js';
import { SidebarProject } from '/src/plugins/editor/js/Sidebar.Project.js';
// import { SidebarHistory } from '/src/plugins/editor/js/Sidebar.History.js';
import { CustomSidebarSettings } from './custom-Sidebar.Settings.js';


function CustomSidebar( editor, parentId=null ) {

	var strings = editor.strings;

	var container = new UITabbedPanel();
	container.setId( 'sidebar' );

	var scene = new UISpan().add(
		new SidebarScene( editor ),
		new SidebarProperties( editor ),
		new SidebarAnimation( editor ),
		new SidebarScript( editor )
	);

	var project = new SidebarProject( editor );

	var settings = new UISpan().add(
		new CustomSidebarSettings( editor,parentId ),
		// new SidebarHistory( editor )
	);

	container.addTab( 'scene', strings.getKey( 'sidebar/scene' ), scene );
	container.addTab( 'project', strings.getKey( 'sidebar/project' ), project );
	container.addTab( 'settings', strings.getKey( 'sidebar/settings' ), settings );
	container.select( 'scene' );

	return container;

}

export { CustomSidebar };