import { UIPanel, UIRow } from '../plugins/editor/js/libs/ui.js';
import store from '@/store';
import { WebIO } from '@gltf-transform/core';
import { DracoMeshCompression } from '@gltf-transform/extensions';
import { KHRONOS_EXTENSIONS } from '@gltf-transform/extensions';
import CryptoJS from 'crypto-js';


function MenubarPublish(editor) {
	var strings = editor.strings;

	var container = new UIPanel();
	container.setClass('menu');

	var title = new UIPanel();
	title.setClass('title');
	title.setTextContent(strings.getKey('menubar/publish'));
	container.add(title);

	var options = new UIPanel();
	options.setClass('options');
	container.add(options);

	const editorSettings = store.state.Editor.cameraSetting != undefined ? store.state.Editor.cameraSetting : {};

	// Examples

	var items = [
		{ title: 'menubar/publish/publish-save', action: 'save' },
		{ title: 'menubar/publish/creat-new-version', action: 'new' },
	];

	for (var i = 0; i < items.length; i++) {
		(function (i) {
			var item = items[i];

			var option = new UIRow();
			option.setClass('option');
			option.setTextContent(strings.getKey(item.title));
			option.onClick(async function () {
				if (item.action == 'new') {
					if (confirm('Any unsaved data will be lost. Are you sure?')) {
					} // todo: use css instead confirm
				}
				else if (item.action == 'save') {
					var scene = editor.scene;
					if (editorSettings != undefined) {
						editorSettings.lights = []

						// Push lights to settings
						scene.traverse((object) => {
							if (object.name.startsWith("PointLight") || object.name.startsWith("AmbientLight")) {
								editorSettings.lights.push({lightObject: object, parentName: object.parent.name})
							}
						})

						for (let light of editorSettings.lights) {
							let lightsParent = editor.scene.getObjectByProperty('name', light.parentName)
							lightsParent.remove(light.lightObject)
						};

						editorSettings.camera = {
							position: editor.camera.position, rotation: { x: editor.camera.rotation.x, y: editor.camera.rotation.y, z: editor.camera.rotation.z }, scale: editor.camera.scale, fov: editor.camera.fov, near: editor.camera.near, far: editor.camera.far, zoom: editor.camera.zoom
						}
						store.dispatch('Editor/setCameraSetting', editorSettings)
					}
					
					var animations = getAnimations(scene);

					var { GLTFExporter } = await import('../plugins/examples/jsm/exporters/GLTFExporter.js');

					var exporter = new GLTFExporter();

					exporter.parse(
						scene,
						function (result) {
							(async () => {
								const io = new WebIO().registerExtensions(KHRONOS_EXTENSIONS).registerDependencies({
									'draco3d.encoder': await new window.DracoEncoderModule(),
									'draco3d.decoder': await new window.DracoDecoderModule(),
								});

								// Load an uncompressed GLB file.
								const document = io.readBinary(result);

								// Configure compression settings.
								document
									.createExtension(DracoMeshCompression)
									.setRequired(true)
									.setEncoderOptions({
										method: DracoMeshCompression.EncoderMethod.EDGEBREAKER,
										encodeSpeed: 5,
										decodeSpeed: 5,
									});
								const arrayBuffer = io.writeBinary(document);
								const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
								const { uuid } = store.state.Project.project;
								const password = uuid.slice(uuid.length - 16);

								// encrypt method
								const key = CryptoJS.enc.Utf8.parse(password);
								const iv = CryptoJS.lib.WordArray.random(16);

								let encryptedArray = CryptoJS.AES.encrypt(wordArray, key, { iv: iv });
								let encrypted = iv.concat(encryptedArray.ciphertext).toString(CryptoJS.enc.Base64);

								let glbFile = new Blob([encrypted], { type: 'application/octet-stream' })


								store.dispatch('Editor/updateGlbEditor', {
									glbFile
								});

								store.commit("Editor/SET_SCENE_CHANGE_STATUS", false);
        						store.commit("Editor/SET_SCENE_FIRST_CHANGE", false);

								// Add the lights back to the scene after saving	
								for (let light of editorSettings.lights) {
									let lightsParent = editor.scene.getObjectByProperty('name', light.parentName)
									lightsParent.add(light.lightObject)
								};
							})();
						},
						{ binary: true, animations: animations }
					);
				}
			});
			options.add(option);
		})(i);
	}


	return container;
}

function getAnimations(scene) {
	var animations = [];

	scene.traverse(function (object) {
		animations.push(...object.animations);
	});

	return animations;
}


export { MenubarPublish };
