import { Editor } from "/src/plugins/editor/js/Editor.js";
import signals from 'signals'


window.signals = signals

function CustomEditor(type = '') {
    Editor.call(this, type)
};
CustomEditor.prototype = new Editor();
CustomEditor.prototype.fromJSON = async function(json) {
    var scope = this;

    var loader = new THREE.ObjectLoader();
    var camera = await loader.parse(json.camera);

    this.camera.copy(camera);
    this.signals.cameraResetted.dispatch();

    this.history.fromJSON(json.history);
    this.scripts = json.scripts;

    await loader.parse(json.scene, function (scene) {

        scope.setScene(scene);

    });
}


export { CustomEditor }