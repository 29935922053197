<template>
  <div id="editor-root">
    <div id="revolva-editor" :key="$store.state.Editor.editor.id"></div>
    <confiramtion-component
      :confirmationText="confText"
      :showActive="leaveConfirmation"
      @accept-confirmation="acceptLeaving"
      @deny-confirmation="denyLeaving"
    />
    <Loading v-if="showAnimation" />
    <div v-if="showAnimation">
      <label>No label</label>
      <b-progress :value="showUploadPercentage" show-progress />
    </div>
    <b-spinner v-if="modelLoading" class="spinner" />
  </div>
</template>

<script>
import { BProgress } from "bootstrap-vue";
import RevolvaEditor from "../../../plugins/RevolvaEditor";
import Loading from "@/plugins/Loading";
import signals from 'signals'
import { SetPositionCommand } from "../../../plugins/editor/js/commands/SetPositionCommand.js";
import { SetScaleCommand } from "../../../plugins/editor/js/commands/SetScaleCommand.js";
import { SetRotationCommand } from "../../../plugins/editor/js/commands/SetRotationCommand.js";
import * as THREE from "../../../plugins/build/three.module.js";
import ConfiramtionComponent from '../../components/ConfiramtionComponent.vue';
import OverlaySpinner from '../../components/overlay/OverlaySpinner.vue';
export default {
  data: () => ({
    editor: null,
    leaveConfirmation: false,
    routeTo: null,
    next: null,
    confText: "Are you sure you want to leave page without saving your changes?",
  }),
  components: {
    Loading,
    BProgress,
    ConfiramtionComponent,
    OverlaySpinner,
  },
  computed: {
    modelLoading() {
      return this.$store.state.Editor.modelLoading;
    },
    showAnimation() {
      return this.$store.state.Editor.showAnimation;
    },
    showUploadPercentage() {
      return this.$store.state.Editor.uploadPercentage;
    },
    sceneStatusChanged() {
      return this.$store.state.Editor.sceneChanged;
    },
    prevntReload() {
      if (this.sceneStatusChanged) {
        //alert("you clicked the button!")
      }
    },
  },
  created() {
    // window.addEventListener("beforeunload", this.prevntReload);
  },
  beforeRouteLeave(to, from, next) {
    if (this.sceneStatusChanged && !this.leaveConfirmation) {
      this.leaveConfirmation = true
      this.routeTo = to
      this.next = next
    } else {
      next((vm) => {
        next(to);
      });
    }
  },
  mounted() {
    window.signals = signals
    this.loadEditor();
    this.$store.commit("Editor/SET_SCENE_CHANGE_STATUS", false);
    this.$store.commit("Editor/SET_SCENE_FIRST_CHANGE", false);
  },
  methods: {
      denyLeaving() {
        this.leaveConfirmation = false;
      },
      acceptLeaving() {
        this.leaveConfirmation = false;
        this.$store.commit("Editor/SET_SCENE_CHANGE_STATUS", false);
        this.$store.commit("Editor/SET_SCENE_FIRST_CHANGE", false);
        let routeTo = this.routeTo
        //next(to);
        this.next((vm) => {
          next(routeTo);
        });
      },
    async loadEditor() {
      await this.$store
        .dispatch("Editor/getEditor", this.$store.state.Editor.editor.id)
        .then(() => {
          this.$store
            .dispatch("Editor/setCameraSetting")
            .then((response) => response.json())
            .then((data) => {
              this.$store.commit("Editor/SET_CAMERA", data);
              this.editor = new RevolvaEditor("revolva-editor");
            });
          
          (async() => {
            while(!this.editor) // when editor is loaded
              await new Promise(resolve => setTimeout(resolve, 100));
            while(!this.editor.editor) // when editor is loaded
              await new Promise(resolve => setTimeout(resolve, 100));
            while(!this.editor.editor.selected) // when editor is loaded
              await new Promise(resolve => setTimeout(resolve, 100));
            this.setCamera();
            this.updateScene();
            this.editor.onWindowResize();
          })();
        })
        .catch((error) => console.log(error));
      
      
    },
    setCamera() {
      const { cameraSetting } = this.$store.state.Editor;
      const camera = cameraSetting.camera ? cameraSetting.camera : undefined;

      if (camera != undefined) {
        const newPosition = new THREE.Vector3(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );

      const scaleValue = new THREE.Vector3(
        camera.scale.x,
        camera.scale.y,
        camera.scale.z
      );
      new SetScaleCommand(this.editor.editor,  this.editor.editor.camera, scaleValue)
        
      const rotationValue = new THREE.Euler(
        camera.rotation.x / THREE.MathUtils.DEG2RAD,
        camera.rotation.y / THREE.MathUtils.DEG2RAD,
        camera.rotation.z / THREE.MathUtils.DEG2RAD
      );

      new SetRotationCommand(this.editor.editor, this.editor.editor.camera, rotationValue)
      this.editor.editor.camera.far = camera.far;
      this.editor.editor.camera.fov = camera.fov;
      this.editor.editor.camera.near = camera.near;
      this.editor.editor.camera.zoom = camera.zoom;
      
      this.editor.editor.execute(
        new SetPositionCommand(this.editor, this.editor.editor.camera, newPosition)      
      );
      this.editor.editor.camera.lookAt(new THREE.Vector3());
    } else {
      this.editor.editor.camera.position.set(0, 5, 15);
      this.editor.editor.camera.lookAt(new THREE.Vector3());
    }
    },
    updateScene() {
      const { cameraSetting } = this.$store.state.Editor;
      const { camera } = cameraSetting
      const object = this.editor.editor.selected;
      if(!camera) {
        const newPosition = new THREE.Vector3();
        newPosition.x = 0;
        newPosition.y = 0;
        newPosition.z = 0;
        this.editor.editor.execute(
          new SetPositionCommand(this.editor, object, newPosition)      
        );
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: absolute;
  z-index: 11;
  top: 63%;
  width: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  position: absolute;
  z-index: 11;
  top: calc(50% + 4rem);
  left: calc(50% - 4rem);
  width: 4rem;
  height: 4rem;
  border: 0.3em solid currentcolor;
  border-right-color: transparent;
}
</style>
