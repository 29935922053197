import { AddObjectCommand } from '/src/plugins/editor/js/commands/AddObjectCommand.js';
import { RGBELoader } from '/src/plugins/examples/jsm/loaders/RGBELoader.js';
import { exportRenderer } from "./custom-Viewport";

import store from '@/store';


class CustomAddObjectCommand extends AddObjectCommand{
    constructor(editor, object) {
        super(editor)
        this.type = 'AddObjectCommand';

		this.object = object;
		if ( object !== undefined ) {

			this.name = `Add Object: ${object.name}`;

		}
	}

    getEnvironemnt() {
        return new Promise((resolve) => {
            const pmremGenerator = new THREE.PMREMGenerator(exportRenderer);
            pmremGenerator.compileEquirectangularShader();
            new RGBELoader().setDataType(THREE.UnsignedByteType).load(this.development_project_files.environment_hdr_s3_path, (texture) => {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            resolve(envMap)
            });
        })
    }

    async execute() {
        let length = this.object.children.length
        if (this.object.name == 'Scene') {
            for (let i = 0; i < length; i++) {
                this.editor.addObject(this.object.children[0])
                
            }
            if (this.selectable != 'no') {
                this.editor.select(this.editor.scene.children[0])
            }
        } else {
            this.editor.addObject(this.object)
            if (this.selectable != 'no') {
                this.editor.select(this.object)
            }
        }

        // get object size by using bounding box
        const box = new THREE.Box3().setFromObject(this.object)
        const size = box.getSize()
        const direction = new THREE.Vector3()
        this.object.getWorldDirection(direction)
        this.editor.camera.position.copy(this.object.position).add(direction.multiplyScalar((size.x + size.y)))

        this.development_project_files = store.getters['Project/getCurrentProjectFiles']

        if (this.development_project_files.environment_hdr_s3_path) {
            this.envMap = await this.getEnvironemnt()
        }

        if (this.envMap) {
            if (!this.envMap.image.data) {
                delete this.envMap.image
            }
            this.editor.scene.traverse((object) => {
                if (object.isMesh && object.name !== 'skydome') {
                    object.material.envMap = this.envMap;
                    object.castShadow = true;
                    object.envMapIntensity = 1.1;
                }
            })
        }
    }
}

export { CustomAddObjectCommand }
