import { Strings } from "/src/plugins/editor/js/Strings.js";


function CustomStrings(config) {
    var language = config.getKey( 'language' );
    var customValues = {
        en: {
            'menubar/publish': 'Publish',
            'menubar/publish/publish-save': 'Save',
            'menubar/publish/creat-new-version': 'Create New Version',
            'menubar/custom-function': 'Custom Function',
            'menubar/custom-function/center-scene': 'Center Scene',
            'menubar/add/annotation': 'Annotation',
            'menubar/lights': 'Lights',
            'menubar/lights/ambient-light': 'Ambient Light',
            'menubar/lights/multiple-point-light': 'Point Light',
        }
    }

    return {
        getKey: function ( key ) {
            let stringsFunction = Strings.call(this, config);
            if (stringsFunction.getKey(key) != '???') {
                return stringsFunction.getKey(key)
            }

            return customValues[ language ][ key ] || '???';
        }
    };
};

export { CustomStrings }
