import { UIPanel, UIRow } from '/src/plugins/editor/js/libs/ui.js';
import { AddObjectCommand } from '@/plugins/editor/js/commands/AddObjectCommand';


function Lights(editor) {
	var strings = editor.strings;

	var container = new UIPanel();
	container.setClass('menu');

	var title = new UIPanel();
	title.setClass('title');
	title.setTextContent(strings.getKey('menubar/lights'));
	container.add(title);

	var options = new UIPanel();
	options.setClass('options');
	container.add(options);

	// Examples

	var items = [
		{ title: 'menubar/lights/ambient-light', action: 'sendAmbientLight' },
		{ title: 'menubar/lights/multiple-point-light', action: 'sendMultiplePointLight' },
	];

	for (var i = 0; i < items.length; i++) {
		(function (i) {
			var item = items[i];

			var option = new UIRow();
			option.setClass('option');
			option.setTextContent(strings.getKey(item.title));
			option.onClick(async function () {
				if (item.action == 'sendAmbientLight') {
					var color = 0x222222;

					var light = new THREE.AmbientLight( color );
					light.name = 'AmbientLight';

					editor.execute( new AddObjectCommand( editor, light ) );
				}
				else if (item.action == 'sendMultiplePointLight') {
					var color = 0xffffff;
					var intensity = 1;
					var distance = 0;
			
					var light = new THREE.PointLight( color, intensity, distance );
					light.name = 'PointLight';
			
					editor.execute( new AddObjectCommand( editor, light ) );
				}
			});
			options.add(option);
		})(i);
	}


	return container;
}

export { Lights };
