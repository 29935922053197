import { DRACOLoader } from '@/plugins/examples/jsm/loaders/DRACOLoader.js'
import { CustomGLTFLoader } from './custom-GLTFLoader.js';


async function loadGLBAsync(filePath, project) {
    return new Promise((resolve, reject) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
        const loader = new CustomGLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        loader.load(
          filePath,
          (model) => {
            const scene = model.scene;
            scene.animations.push(...model.animations);
            resolve(scene)
          },
        );
    })
  }

export { loadGLBAsync }