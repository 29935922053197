<template>
  <div class="wrapper">
    <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li>
        <h1>
          We are saving and optimizing the file to let your clients have the
          best/fast experience
        </h1>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  z-index: 11;
  width: calc(100% + 4rem);
  height: 100vh;
  background-color: #f6f6f6;
  top: -110px;
  left: -2rem;
}
ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ul li {
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-top: 5px solid #1f2437;
  border-right: 5px solid #1f2437;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  box-sizing: border-box;
}

ul li:nth-child(1) {
  width: 300px;
  height: 300px;
  animation: animateOdd 2s infinite linear;
}

ul li:nth-child(2) {
  width: 230px;
  height: 230px;
  animation: animateEven 2s infinite linear;
}

ul li:nth-child(3) {
  width: 160px;
  height: 160px;
  animation: animateOdd 2s infinite linear;
}

ul li:nth-child(4) {
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: none;
  background: #e6354a;
}

ul li:nth-child(5) {
  border: none;
  top: 230px;
  font-size: 20px;
  width: 60vw;
  left: 50% !important;
  transform: translateX(-50%);
  text-align: center;
}

@keyframes animateEven {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes animateOdd {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
</style>