import { UIPanel } from '/src/plugins/editor/js/libs/ui.js';

import { MenubarAdd } from '/src/plugins/editor/js/Menubar.Add.js';
import { MenubarEdit } from './Menubar.Edit.js';
import { MenubarFile } from '/src/plugins/editor/js/Menubar.File.js';
import { MenubarExamples } from '/src/plugins/editor/js/Menubar.Examples.js';
import { MenubarView } from '/src/plugins/editor/js/Menubar.View.js';
import { MenubarHelp } from '/src/plugins/editor/js/Menubar.Help.js';
import { MenubarPlay } from '/src/plugins/editor/js/Menubar.Play.js';
import { MenubarStatus } from './custom-Menubar.Status.js';
import { MenubarPublish } from './Menubar.Publish.js';
import { CustomFunctions } from './CustomFunctions.js';
import { Lights } from './Lights.js';


function Menubar(editor) {
	const container = new UIPanel();
	container.setId('menubar');

	container.add(new MenubarFile(editor));
	container.add(new MenubarEdit(editor));
	container.add(new MenubarAdd(editor));
	container.add(new MenubarPlay(editor));
	container.add(new MenubarExamples(editor));
	container.add(new MenubarView(editor));
	container.add(new MenubarHelp(editor));

	container.add(new MenubarStatus(editor));

	container.add(new MenubarPublish(editor));
	container.add(new CustomFunctions(editor));
	container.add(new Lights(editor))

	return container;

}

export { Menubar };
