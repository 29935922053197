<template>
    <b-overlay :show="showActive" no-wrap>
      <template #overlay>
        <b-card-text class="font-weight-bolder">
          {{ confirmationText }}
        </b-card-text>
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            ref="dialog"
            variant="danger"
            @click="deny"
            class="mr-3"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            ref="dialog"
            variant="success"
            @click="accept"
            style="padding:10px"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-overlay>
</template>
<script>
import {
  BButton,
  BOverlay,
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormRadioGroup,
  BFormFile,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormRadioGroup,
    BFormFile,
    BCardText,
    BFormCheckbox,
  },
  props: {
      showActive: {
          type: Boolean,
          required: true,
      },
      confirmationText: {
        type: String,
        required: false,
      }
  },
  methods: {
    accept() {
      this.$emit('accept-confirmation')
    },
    deny() {
      this.$emit('deny-confirmation')
    }
  }
}
</script>
