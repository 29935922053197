import { ViewportInfo } from '/src/plugins/editor/js/Viewport.Info.js';


function CustomViewportInfo(editor) {
    return ViewportInfo.call(this, editor)

};

CustomViewportInfo.prototype = Object.create(ViewportInfo.prototype);
CustomViewportInfo.prototype.update = function() {

    var scene = CustomViewportInfo.editor.scene;

    var objects = 0, vertices = 0, triangles = 0;

    for ( var i = 0, l = scene.children.length; i < l; i ++ ) {

        var object = scene.children[ i ];

        object.traverseVisible( function ( object ) {

            objects ++;

            if ( object.isMesh ) {

                var geometry = object.geometry;

                if ( geometry.isGeometry ) {

                    vertices += geometry.vertices.length;
                    triangles += geometry.faces.length;

                } else if ( geometry.isBufferGeometry ) {

                    vertices += geometry.attributes.position.count;

                    if ( geometry.index !== null ) {

                        triangles += geometry.index.count / 3;

                    } else {

                        triangles += geometry.attributes.position.count / 3;

                    }

                }

            }

        } );

    }

    objectsText.setValue( objects.format() );
    verticesText.setValue( vertices.format() );
    trianglesText.setValue( triangles.format() );

}


export { CustomViewportInfo }
