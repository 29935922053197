import { LoaderUtils } from '/src/plugins/build/three.module.js';
import { GLTFLoader } from '/src/plugins/examples/jsm/loaders/GLTFLoader.js';
import { CustomFileLoader } from './custom-three.module.js'
import store from '@/store'
import CryptoJS from 'crypto-js';


function CustomGLTFLoader() { };
CustomGLTFLoader.prototype = new GLTFLoader();
CustomGLTFLoader.prototype.load = function (url, onLoad, onProgress, onError) {
    var scope = this;
    var resourcePath;
    if (this.resourcePath !== '') {
        resourcePath = this.resourcePath;
    } else if (this.path !== '') {
        resourcePath = this.path;
    } else {
        resourcePath = LoaderUtils.extractUrlBase(url);
    }
    // Tells the LoadingManager to track an extra item, which resolves after
    // the model is fully loaded. This means the count of items loaded will
    // be incorrect, but ensures manager.onLoad() does not fire early.
    this.manager.itemStart(url);

    var _onError = function (e) {

        if (onError) {

            onError(e);

        } else {

            console.error(e);

        }

        scope.manager.itemError(url);
        scope.manager.itemEnd(url);

    };

    var loader = new CustomFileLoader(this.manager);

    loader.setPath(this.path);
    const { is_encrypted } = store.state.Editor.editor;
    if (!is_encrypted) {
        loader.setResponseType('arraybuffer');
    }
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);

    loader.load(url, function (data) {
        try {
            const { uuid } = store.state.Project.project;
            if (is_encrypted) {
                const password = uuid.slice(uuid.length - 16);
                const key = CryptoJS.enc.Utf8.parse(password);
                let ciphertext = CryptoJS.enc.Base64.parse(data);
                let iv = ciphertext.clone();
                iv.sigBytes = 16;
                iv.clamp();
                ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
                ciphertext.sigBytes -= 16;

                //decryption
                let decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
                    iv: iv
                });
                const d = convertWordArrayToUint8Array(decrypted);
                data = d.buffer;
            }

            scope.parse(data, resourcePath, function (gltf) {

                onLoad(gltf);

                scope.manager.itemEnd(url);

            }, _onError);

        } catch (e) {

            _onError(e);

        }

    }, onProgress, _onError);

}


function convertWordArrayToUint8Array(wordArray) {
    var arrayOfWords = wordArray.hasOwnProperty('words') ? wordArray.words : [];
    var length = wordArray.hasOwnProperty('sigBytes') ? wordArray.sigBytes : arrayOfWords.length * 4;
    var uInt8Array = new Uint8Array(length),
        index = 0,
        word,
        i;
    for (i = 0; i < length; i++) {
        word = arrayOfWords[i];
        uInt8Array[index++] = word >> 24;
        uInt8Array[index++] = (word >> 16) & 0xff;
        uInt8Array[index++] = (word >> 8) & 0xff;
        uInt8Array[index++] = word & 0xff;
    }
    return uInt8Array;
}

export { CustomGLTFLoader }
